export default [
  {
    id: 'home',
    icon: 'home',
    title: 'Start',
    component: 'c-home-section-home',
  },
  {
    id: 'facts',
    icon: 'user',
    title: 'Facts',
    component: 'c-home-section-me',
  },
  {
    id: 'preview',
    icon: 'development',
    title: 'Preview',
    component: 'c-home-section-work',
  },
  {
    id: 'contact',
    icon: 'contact',
    title: 'Contact',
    component: 'c-home-section-contact',
  },
];
